import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const router = new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        {
            name: 'Login',
            path: '/login',
            component: () => import('@/views/login/Login'),
        },
        {
            path: '/',
            component: () => import('@/views/dashboard/Index'),
            children: [
                // Dashboard
                {
                    name: 'Dashboard',
                    path: '',
                    component: () => import('@/views/dashboard/Dashboard'),
                },
                // Pages
                {
                    name: '',
                    // redirect: 'pages/user',
                    path: 'produk',
                    component: () => import('@/views/dashboard/components/core/Wrapper'),
                    children: [
                        {
                            name: 'Kategori Produk',
                            // redirect: 'pages/user',
                            path: 'kategori',
                            // component: () => import('@/views/dashboard/pages/UserProfile'),
                            component: () => import('@/views/dashboard/pages/ProductsCategory/IndexProductsCategory'),
                        },
                        {
                            name: 'Produk',
                            // redirect: 'pages/user',
                            path: '/',
                            // component: () => import('@/views/dashboard/pages/UserProfile'),
                            component: () => import('@/views/dashboard/pages/Products/IndexProducts'),
                        },
                        {
                            name: 'Form Tambah Produk',
                            // redirect: 'pages/user',
                            path: 'tambah',
                            // component: () => import('@/views/dashboard/pages/UserProfile'),
                            component: () => import('@/views/dashboard/pages/Products/FormProducts'),
                        },
                        {
                            name: 'Form Edit Produk',
                            // redirect: 'pages/user',
                            path: 'edit/:id',
                            // component: () => import('@/views/dashboard/pages/UserProfile'),
                            component: () => import('@/views/dashboard/pages/Products/FormProducts'),
                        },
                        {
                            name: 'Promosi',
                            // redirect: 'pages/user',
                            path: 'promosi',
                            // component: () => import('@/views/dashboard/pages/UserProfile'),
                            component: () => import('@/views/dashboard/pages/Promotion/IndexPromotion'),
                        },
                        {
                            name: 'Form Tambah Promosi',
                            // redirect: 'pages/user',
                            path: 'promosi/tambah',
                            // component: () => import('@/views/dashboard/pages/UserProfile'),
                            component: () => import('@/views/dashboard/pages/Promotion/FormPromotion'),
                        },
                        {
                            name: 'Form Edit Promosi',
                            // redirect: 'pages/user',
                            path: 'promosi/edit/:id',
                            // component: () => import('@/views/dashboard/pages/UserProfile'),
                            component: () => import('@/views/dashboard/pages/Promotion/FormPromotion'),
                        },
                    ]
                },
                {
                    name: '',
                    // redirect: 'pages/user',
                    path: 'barang',
                    component: () => import('@/views/dashboard/components/core/Wrapper'),

                    // },
                    // {
                    // name: 'Tes',
                    // path: 'pages',
                    children: [
                        {
                            name: '',
                            // redirect: 'pages/user',
                            path: 'masuk',
                            // component: () => import('@/views/dashboard/pages/UserProfile'),
                            component: () => import('@/views/dashboard/components/core/Wrapper'),
                            children: [
                                {
                                    name: 'Barang Masuk',
                                    path: '/',
                                    component: () => import('@/views/dashboard/pages/GoodsReceipt/IndexGoodsReceipt'),
                                },
                                {
                                    name: 'Form Tambah Barang Masuk',
                                    path: 'tambah',
                                    component: () => import('@/views/dashboard/pages/GoodsReceipt/FormGoodsReceipt'),
                                },
                                {
                                    name: 'Form Edit Barang Masuk',
                                    path: 'edit/:date',
                                    component: () => import('@/views/dashboard/pages/GoodsReceipt/FormGoodsReceipt'),
                                },
                            ]
                        },
                        {
                            name: 'Kategori Barang',
                            path: 'kategori',
                            // component: () => import('@/views/dashboard/tables/RegularTables'),
                            component: () => import('@/views/dashboard/pages/GoodsCategory/IndexGoodsCategory'),
                        },
                        {
                            name: 'Barang',
                            path: '/',
                            component: () => import('@/views/dashboard/components/core/Wrapper'),
                            children: [
                                {
                                    name: 'Barang',
                                    path: '/',
                                    component: () => import('@/views/dashboard/pages/Goods/IndexGoods'),
                                },
                                {
                                    name: 'Form Tambah Barang',
                                    path: 'tambah',
                                    component: () => import('@/views/dashboard/pages/Goods/FormGoods'),
                                },
                                {
                                    name: 'Form Edit Barang',
                                    path: 'edit/:id',
                                    component: () => import('@/views/dashboard/pages/Goods/FormGoods'),
                                },
                            ]
                        },
                    ]


                },
                {
                    name: 'Laporan',
                    path: 'laporan',
                    component: () => import('@/views/dashboard/components/core/Wrapper'),
                    children: [
                        {
                            name: 'Laporan Laba/Rugi',
                            path: 'labarugi',
                            component: () => import('@/views/dashboard/pages/Laporan/LaporanLabaRugi'),
                        },
                        {
                            name: 'Laporan Penjualan',
                            path: 'penjualan',
                            component: () => import('@/views/dashboard/pages/Laporan/LaporanPenjualan'),
                        },
                        {
                            name: 'Laporan Pembelian',
                            path: 'pembelian',
                            component: () => import('@/views/dashboard/pages/Laporan/LaporanPembelian'),
                        },
                    ]
                },
                {
                    name: 'Notifications',
                    path: 'components/notifications',
                    component: () => import('@/views/dashboard/component/Notifications'),
                },

                {
                    name: 'Satuan Stok',
                    path: '/uom',
                    component: () => import('@/views/dashboard/pages/Uom/IndexUom'),
                },
                {
                    name: 'Banner',
                    path: '/banner',
                    component: () => import('@/views/dashboard/components/core/Wrapper'),
                    children: [
                        {
                            name: 'Banner',
                            path: '/',
                            component: () => import('@/views/dashboard/pages/Banner/IndexBanner'),
                        },
                        {
                            name: 'Form Tambah Banner',
                            path: 'tambah',
                            component: () => import('@/views/dashboard/pages/Banner/FormBanner'),
                        },
                        {
                            name: 'Form Edit Banner',
                            path: 'edit/:id',
                            component: () => import('@/views/dashboard/pages/Banner/FormBanner'),
                        },
                    ]
                },
                {
                    name: 'Supplier',
                    path: '/suppliers',
                    component: () => import('@/views/dashboard/pages/Suppliers/IndexSuppliers'),
                },
                {
                    name: 'Pelanggan',
                    path: '/customers',
                    component: () => import('@/views/dashboard/pages/Customers/IndexCustomers'),
                },
                {
                    name: 'Penjualan',
                    path: '/sales',
                    component: () => import('@/views/dashboard/pages/Sales/IndexSales'),
                },
                {
                    name: 'Form Penjualan',
                    path: '/sales/tambah',
                    component: () => import('@/views/dashboard/pages/Sales/FormSales'),
                },
                {
                    name: 'Setting',
                    path: 'setting',
                    component: () => import('@/views/dashboard/components/core/Wrapper'),
                    children: [
                        {
                            name: 'Setting User',
                            path: 'user',
                            component: () => import('@/views/dashboard/pages/Setting/IndexUsers'),
                        }
                    ]
                },
                {
                    name: 'Icons',
                    path: 'components/icons',
                    component: () => import('@/views/dashboard/component/Icons'),
                },
                {
                    name: 'Buttons',
                    path: 'components/buttons',
                    component: () => import('@/views/dashboard/component/Buttons'),
                },
                {
                    name: 'Grid',
                    path: 'components/grid',
                    component: () => import('@/views/dashboard/component/Grid'),
                },
                {
                    name: 'Tabs',
                    path: 'components/tabs',
                    component: () => import('@/views/dashboard/component/Tabs'),
                },
                {
                    name: 'Typography',
                    path: 'components/typography',
                    component: () => import('@/views/dashboard/component/Typography'),
                },
                // Tables
                // {
                //     name: 'Regular Tables',
                //     path: 'regular-tables',
                //     component: () => import('@/views/dashboard/tables/RegularTables'),
                // },
                // Maps
                {
                    name: 'Google Maps',
                    path: 'maps/google-maps',
                    component: () => import('@/views/dashboard/maps/GoogleMaps'),
                },
                // Upgrade
                {
                    name: 'Upgrade',
                    path: 'upgrade',
                    component: () => import('@/views/dashboard/Upgrade'),
                },
                {
                    path: '*',
                    redirect: '/',
                    component: () => import('@/views/dashboard/Dashboard'),
                }
            ],
        }
    ],
})

router.beforeEach((to, from, next) => {
    console.log(store.state);
    console.log(to, store.state.isLogin, store.state.currentUser)
    if (to.name !== 'Login' && !store.state.isLogin && !store.state.currentUser) next({name: 'Login'})
    else if (to.name === 'Login' && store.state.isLogin && store.state.currentUser) next({name: 'Dashboard'})
    else
        next()
})

export default router